import React from "react";
import { NavLink } from "react-router-dom";
// Router
import { Link } from "react-scroll";

export default function OrderNavLink({ path, text, onClick }) {
    return (
        <li className="link">
            <NavLink
                className="anchor"
                activeClass="active"
                to={path}
                onClick={onClick}
                exact
            >
                {text}
            </NavLink>
        </li>
    )
};
