import OrderMenu from "components/Menu/OrderMenu";
import Nav from "components/Nav/Nav";
import "../components/OrderNow/OrderNow.css"
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Button from "components/Button/Button";
import { motion } from "framer-motion";
import man from "../images/developeratwork.svg";
import why from "../images/question.png";
import { NavLink } from "react-router-dom";


export default function Orders() {
  const [menuOpen, setMenuOpen] = useState(false);
  const contentVariants = {
    initial: {
      translateX: "-100vw",
      opacity: 0,
    },

    animate: {
      translateX: "0vw",
      opacity: 1,
      transition: {
        duration: 2,
        when: "beforeChildren",
      },
    },
  };
  return (<>
    <Nav menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
    <OrderMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
    <div className="order-now-home" >
      <motion.div className="order-now-home-divs content" variants={contentVariants} initial="initial" animate="animate">
        <h2>Hi Devs!</h2>
        <h1>Its is essy to hire me</h1>
        <p>Just Place a order</p>
        <NavLink
          className="anchor"
          activeClass="active"
          to="/order/new-order"
          exact
        >
          <Button text={"Hire me"} type="submit" />
        </NavLink>

      </motion.div>
      <motion.div
        className="order-now-home-divs svg"
        animate={{ translateY: [-20, 0, -20, 0] }}
        transition={{ yoyo: Infinity, duration: 6 }}
      >
        <img src={man} alt="Developer" />
      </motion.div>
    </div>
    <div className="benifits-parrent">
      <div className="order-now-benifit">
        <div className="order-now-home-divs benifits">
          <h2>Why this?</h2>
          <ul>
            <li>No trust issue.</li>
            <li>Order recipt.</li>
            <li>Essay to pay.</li>
            <li>No other charges.</li>
            <li>All work record.</li>
          </ul>
        </div>
        <div className="order-now-home-divs svg">
        <img src={why} alt="Developer" />
        </div>
      </div>
    </div>

  </>);
}