import React, {  useState,useLayoutEffect } from "react";
import { ref, onValue } from "firebase/database";
import Menu from "components/Menu/Menu";
import Nav from "components/Nav/Nav";
import Home from "components/Home/Home";
import Portfolio from "components/Portfolio";
import Contact from "components/Contact/Contact";
import Services from "components/Services/Services";
import Testimonials from "components/Testimonials/Testimonials";
import Footer from "components/Footer/Footer";

import { APP_LOAD, database } from "conectDb";

export default function HomePage() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [projects, setProjects] = useState([]);
    const [services, setServices] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const location = ref(database, APP_LOAD);

    useLayoutEffect(() => {
        let tempTestimonials = [];
        let tempProjects = [];
        let tempServices = [];
        let tempData = [];

        onValue(location, snapshot => {
            if (snapshot.exists) {
                tempData = snapshot.val();
                tempData.map(data => {
                    let doc = data
                    if (doc.country) {
                        tempTestimonials.push(doc);
                    } else if (doc.liveUrl) {
                        tempProjects.push(doc);
                    } else {
                        tempServices.push(doc);
                    }
                })
                setTestimonials(tempTestimonials);
                setProjects(tempProjects);
                setServices(tempServices);
            }

        })
    }, []);
    return (
        <>
        <Nav menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <Home />
        <Services services={services} />
        <Portfolio projects={projects} />
        <Testimonials testimonials={testimonials} />
        <Contact />
        <Footer />
    </>
    )
}