import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDmFVlOLRgYIo0KSkO4QgkACIbnHh9y7ns",
  authDomain: "govtechnologies.firebaseapp.com",
  databaseURL: "https://govtechnologies-default-rtdb.firebaseio.com",
  projectId: "govtechnologies",
  storageBucket: "govtechnologies.appspot.com",
  messagingSenderId: "619052842987",
  appId: "1:619052842987:web:392a5b85340f97acc1e3ae",
  measurementId: "G-QS8FT7Z6S8"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const database = getDatabase(app);
export const storage = getStorage(app);
export const APP_LOAD = "data";