import React from "react";
import "./menu.css";
// Router
import { NavLink } from "components/Nav/NavLink/NavLink";
import OrderNavLink from "components/Nav/NavLink/OrderNavLink";

const OrderMenu = ({ menuOpen, setMenuOpen }) => {
  return (
    <div className={"menu " + (menuOpen && "active")}>
      <ul>
        <OrderNavLink text="Home" path="/" onClick={() => setMenuOpen(false)} />
        <OrderNavLink
          text="New Order"
          path="new-order"
          onClick={() => setMenuOpen(false)}
        />
        <OrderNavLink
          text="Orders"
          path="order-list"
          onClick={() => setMenuOpen(false)}
        />
      </ul>
    </div>
  );
};

export default OrderMenu;