import React, { useEffect, } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Components
import HomePage from "screen/homescreen";
import Orders from "screen/orders";
import OrderNow from "components/OrderNow/OrderNow";
// Animation
import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({ duration: 2000, offset: 10 });
  }, []);

  return (
    <div className="app-container">
      <Router>
        <Routes>
          <Route path="/" element={< HomePage />} />
          <Route path="/order" element={<Orders />} >
            <Route path="/order/new-order" element={<OrderNow />} />
            <Route path="/order/order-list" element={<OrderNow />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
